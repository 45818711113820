$(document).on('turbo:load', function() {
  // responsive mobile page-footer
  $('.page-footer-mobile__item--desktop').on('click', function(e) {
    e.preventDefault();
    $('body')
      .removeClass('is-responsive')
      .addClass('is-responsive--desktop');
  });

  $('.page-footer-mobile__item--mobile').on('click', function(e) {
    e.preventDefault();
    $('body')
      .removeClass('is-responsive--desktop')
      .addClass('is-responsive');
  });
});
