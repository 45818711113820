(function() {
  const isTurbolinks = !!window.Turbolinks;
  console.log('Turbolinks compatibility script loaded');

  // Define unified event names
  const EVENTS = {
    click: 'turbo:click',
    beforeVisit: 'turbo:before-visit',
    visit: 'turbo:visit',
    requestStart: 'turbo:request-start',
    requestEnd: 'turbo:request-end',
    beforeCache: 'turbo:before-cache',
    beforeRender: 'turbo:before-render',
    render: 'turbo:render',
    load: 'turbo:load'
  };

  // Handle click event to manage navigation
  document.addEventListener('click', function(event) {
    console.log('Click event detected:', event);
    // Check if the clicked element is an <a> tag
    if (event.target && event.target.tagName.toLowerCase() === 'a') {
      const anchor = event.target;

      // Prevent default navigation
      if (event.defaultPrevented) return;

      if (isTurbolinks) {
        // Turbolinks will handle navigation by default
        if (
          anchor.hasAttribute('data-turbolinks') &&
          anchor.getAttribute('data-turbolinks') === 'false'
        ) {
          return; // Turbolinks should not handle this click
        }

        // Trigger navigation via Turbolinks if it's not disabled
        Turbolinks.visit(anchor.href);
        event.preventDefault();
      }
    }
  });

  // Dispatch unified events
  function dispatchEvent(name, e) {
    console.log('Dispatching event: *******', name, e);
    const event = new Event(name, e);
    document.dispatchEvent(event);
  }

  // Listen to native events and re-emit as unified events
  if (isTurbolinks) {
    document.addEventListener('turbolinks:click', e =>
      dispatchEvent(EVENTS.click, e)
    );
    document.addEventListener('turbo:before-visit', e =>
      dispatchEvent(EVENTS.beforeVisit, e)
    );
    document.addEventListener('turbolinks:visit', e =>
      dispatchEvent(EVENTS.visit, e)
    );
    document.addEventListener('turbolinks:request-start', e =>
      dispatchEvent(EVENTS.requestStart, e)
    );
    document.addEventListener('turbolinks:request-end', e =>
      dispatchEvent(EVENTS.requestEnd, e)
    );
    document.addEventListener('turbo:before-cache', e =>
      dispatchEvent(EVENTS.beforeCache, e)
    );
    document.addEventListener('turbo:before-render', e =>
      dispatchEvent(EVENTS.beforeRender, e)
    );
    document.addEventListener('turbo:render', e =>
      dispatchEvent(EVENTS.render, e)
    );
    document.addEventListener('turbolinks:load', e =>
      dispatchEvent(EVENTS.load, e)
    );
  }
})();
