$(document).on 'turbo:load', ->
  return unless Sofatutor.logged_in

  Sofatutor.profile =
    set: (profileId, profileType, redirectPath = null) ->
      return unless Sofatutor.consentGivenForCategory('necessary')

      cookieExpirationMinutes = 30
      cookieExpirationDate = new Date(Date.now() + cookieExpirationMinutes * 60000)
      cookieProps = { profile_id: profileId, profile_type: profileType, expires: cookieExpirationDate }

      $.cookie('_sofatutor_active_profile', JSON.stringify(cookieProps), path: '/')
      if $.cookie('_sofatutor_profile_switch_parents_hidden')
        $.removeCookie('_sofatutor_profile_switch_parents_hidden')

      @redirectTo(redirectPath)

    isSet: ->
      $.cookie('_sofatutor_active_profile')?

    currentProfileType: ->
      return unless $.cookie('_sofatutor_active_profile')

      @parsedProfileCookie()['profile_type'] || @parsedProfileCookie()['profile']

    currentProfileId: ->
      return unless $.cookie('_sofatutor_active_profile')

      @parsedProfileCookie()['profile_id']

    parsedProfileCookie: ->
      JSON.parse($.cookie('_sofatutor_active_profile'))

    redirectTo: (redirectPath) ->
      if Sofatutor.browser.isMobileApp
        Turbolinks.clearCache()

        redirectUrl = window.Sofatutor.MobileApp.profileRedirectPath || redirectPath
        Turbolinks.visit(redirectUrl, { action: 'replace' })

        Sofatutor.MobileApp.reloadTabs()
      else if redirectPath?
        Turbolinks.visit(redirectPath, { action: 'advance' })
      else
        Turbolinks.visit(window.location.toString(), { action: 'replace' })

    showChooseProfilePopup: () ->
      document && document.dispatchEvent(new CustomEvent('showChooseProfilePopup'))
