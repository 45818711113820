/* eslint-disable no-undef */
import { initAnimateProgressBar } from '../about/animate_progress';

$(document).on('turbo:load', function() {
  var touchDevice = !!navigator.userAgent.match(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
  );

  var deviceSize = new Sofatutor.Classes.DeviceSizeHelper();
  var $pageHeader = $('.page-header');

  // Eventlistener for learning-success links in  mobile and desktop navigation
  $(
    '.page-header-flyout__link[href="/erfahrungen"], .card-section[href="/erfahrungen"]'
  ).on('click', function() {
    $(document).on('turbo:render', function() {
      if ($('body').hasClass('learning-success')) {
        initAnimateProgressBar();
      }
    });
  });

  // page-header phones sub menu
  $('.js-page-header-toggle-submenu', $pageHeader).on('click', function() {
    var targetSubmenuId = $(this).data('toggle-submenu');

    $(this).toggleClass('is-open');
    $(this).toggleClass('is-closed');
    $('#' + targetSubmenuId).slideToggle();
  });

  // page-header dropdowns (menu)
  $('.page-header__menu').on('click', function() {
    if ($('.js-mobile-main-list, .js-user-menu-mobile').is(':visible')) {
      $(this).addClass('is-closed');
      $(this).removeClass('is-open');
    } else {
      $(this).addClass('is-open');
      $(this).removeClass('is-closed');
    }
    $('.page-header-main__list--phones, .js-user-menu-mobile').slideToggle();
  });

  var menu = {
    toggle: function() {
      if (window.matchMedia('(min-width: 640px)').matches) {
        $('.page-header__menu').removeClass('is-open');
        $('.page-header-main__list--phones, .js-user-menu-mobile').hide();
      }
    }
  };

  $(window).resize(function() {
    menu.toggle();
  });
  menu.toggle();

  // phone header logo animation
  if (
    deviceSize.isPhone() &&
    Sofatutor.consentGivenForCategory('marketing') &&
    !$.cookie('_sofatutor_animated_header_seen')
  ) {
    $('.page-header__logo').addClass('page-header__logo--animated');
    $.cookie('_sofatutor_animated_header_seen', true, { path: '/' });
  }

  // page-header dropdowns (login/user)
  if (touchDevice) {
    $('.page-header-login').removeClass('js-dropdown-toggle');
  }

  // page-header dropdowns subjects
  if (deviceSize.isTablet() && touchDevice) {
    $('.page-header-main__item.js-dropdown-toggle').removeClass(
      'js-dropdown-toggle'
    );
    $('.js-header-search-input').removeClass('js-autocomplete');
  }

  $('.js-dropdown-toggle > a').on('click', function(e) {
    e.preventDefault();
  });

  $('.js-dropdown-toggle')
    .on('mouseenter', function() {
      var $this = $(this);
      $('.page-header-flyout').fadeOut();
      // open dropdown when hovering over toggle link
      $this.addClass('is-active');

      $('.js-dropdown', $this)
        .stop(true, true)
        .show();
    })
    .on('mouseleave', function() {
      if ($(this).data('dropdownPinned')) return;

      // close dropdown on mouseleave unless pinned
      $(this).removeClass('is-active');
      var $flyout = $('.js-dropdown', this);
      $flyout.fadeOut('fast');
      $flyout.css('overflow-y', 'initial');
      $flyout.height('auto');
    })
    .on('click', function(e) {
      if (!$(e.target).is($('.js-pin-dropdown'))) return;

      // toggle dropdown pin when clicking on toggle link or user image
      $(this).data('dropdownPinned', !$(this).data('dropdownPinned'));
      $(this).trigger(
        $(this).data('dropdownPinned') ? 'mouseenter' : 'mouseleave'
      );
    })
    .on('clickoutside', function(e) {
      // unpin and close dropdown when clicking outside
      $(this).data('dropdownPinned', false);
      $(this).trigger('mouseleave');
    });

  // pin dropdown when selecting login input
  $('.js-dropdown-toggle.page-header-login').on(
    'click keydown',
    '.page-header-login__dropdown',
    function(event) {
      if (event.key)
        $('.js-dropdown-toggle.page-header-login').data('dropdownPinned', true);
    }
  );

  // smart select login field
  $('.page-header-login').on('mouseenter', function() {
    $($('#user_email').val() ? '#user_password' : '#user_email').focus();
  });

  $('.page-header-login__dropdown.login-failed')
    .closest('.page-header-login')
    .data('dropdownPinned', true);

  // Profile switch button
  $('.js-profile-switch-dropdown').on('click', function(event) {
    var profileId = $(event.currentTarget).data('profile-id');
    var profileType = $(event.currentTarget).data('profile-type');

    Sofatutor.profile.set(profileId, profileType);
  });

  // amplitude events for page header
  const amplitudeEventNames = document.querySelectorAll(
    '.js-page-header-amplitude-event'
  );

  const triggerAmplitudeTracking = eventName => {
    if (
      !Sofatutor.logged_in ||
      !Sofatutor.user.isFull ||
      Sofatutor.user.isTeacherPlan
    ) {
      return;
    }
    Sofatutor.tracking.amplitude.track(eventName, {
      profile_type: Sofatutor.user.profile_type,
      profile_id: Sofatutor.user.profile_id,
      profile_level: Sofatutor.user.level,
      ref_page: window.location.href
    });
  };

  amplitudeEventNames?.forEach(amplitudeEventName => {
    amplitudeEventName.addEventListener('click', () => {
      triggerAmplitudeTracking(
        amplitudeEventName.getAttribute('data-amplitude-event-name')
      );
    });
  });
});

// Close dropdown before caching the page
$(document).on('turbo:before-cache', function() {
  $('.js-dropdown-toggle').removeClass('is-active');
  $('.js-dropdown').hide();
});
