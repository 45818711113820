const closeBannerButton = () => {
  const closeButtons = document.querySelectorAll('.js-close-banner-button');

  const updateBannerSeen = e => {
    $.ajax({
      type: 'PATCH',
      url: $(e.currentTarget).data('update-path'),
      success:
        $(e.currentTarget).hasClass('page-banner__close-icon') &&
        $(e.currentTarget)
          .closest('.page-banner')
          .remove()
    });
  };

  closeButtons.forEach(button => {
    button.addEventListener('click', updateBannerSeen);
  });
};

document.addEventListener('turbo:load', () => {
  const closeButtons = document.querySelectorAll('.js-close-banner-button');
  if (closeButtons.length) {
    closeBannerButton();
  }
});
