const teacherFeedbackPopup = () => {
  new Foundation.Reveal($('#teacher-feedback-popup'));

  $('.js-submit-feedback-button').on('click', () => {
    $.ajax({
      method: 'POST',
      url: $('#teacher-feedback-popup').data('path'),
      data: {
        survey_type: 'teacher_feedback',
        text_response: $('.js-feedback-textarea').val(),
        options: { user_type: $('#teacher-feedback-popup').data('user-type') }
      }
    });
  });
};

$(document).on('turbo:before-visit', () => {
  Turbolinks.clearCache();
});

$(document).on('turbo:load', () => {
  if (document.querySelector('#teacher-feedback-popup')) {
    teacherFeedbackPopup();
  }
});
