document.addEventListener('turbo:load', () => {
  const images = document.querySelectorAll('img.lazy-image');

  const lazyLoadOptions = {
    rootMargin: '800px 0px',
    threshold: 0
  };

  const lazyLoad = image => {
    const src = image.getAttribute('data-src');
    if (!src) {
      return;
    }

    image.setAttribute('src', src);
    image.classList.remove('lazy-image');
  };

  const imageObserver = new IntersectionObserver((entries, imageObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        lazyLoad(entry.target);
        imageObserver.unobserve(entry.target);
      } else {
        return;
      }
    });
  }, lazyLoadOptions);

  if (images.length) {
    images.forEach(image => imageObserver.observe(image));
  }
});
